import React, {Fragment, useEffect, useState} from "react";
import { ContentsSection } from "component/app/items";
import { Header } from "component/elements/header";
import { LeftMenu } from "component/elements/leftMenu";
import moment from "moment/moment";
import {useNavigate, useParams} from "react-router-dom";
import useGet from "../../api/useGet";
import {Line, Radar} from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    Legend,
    LinearScale,
    LineElement,
    radialLinear,
    PointElement,
    Title,
    Tooltip,
    RadialLinearScale, Filler
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import {MenuTitle} from "../../component/menu/menuTitle";
import {TableSection, TableTd, TableTr} from "../../component/basic";

const Main = (props) => {
    let navigate = useNavigate();
    const id = useParams().id;

    ChartJS.register(
        RadialLinearScale,
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        annotationPlugin
    );

    const [chartType, setChartType] =  useState('hour');
    const [startDate, setStartDate] =  useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] =  useState(moment().format('YYYY-MM-DD'));
    const [date, setDate] =  useState(moment().format('YYYY-MM-DD'));
    const [tab, setTab] =  useState({'name': 'Tree vitality', 'code': '11'});
    const [mouseOver, setMouseOver] =  useState('');
    const [chartData, setChartData] =  useState({labels: [], datasets: [{label: "", data: []}],});
    const [radarData, setRadarData] = useState({
        labels: ["Vitality", "Irrigation", "Damage", "Soil", "Safety", "Pruning"],
        datasets: [
            {
                label: "Scores",
                data: [0, 0, 0, 0, 0, 0],
                borderColor: "#6FD195",
                borderWidth: 2,
                pointRadius: 0,
                backgroundColor: 'rgba(111, 209, 149, 0.3)',
            },
        ],
    });

    const insightApi = useGet({url:`/insight?data_code=${tab?.code}&start_date=${startDate}&end_date=${endDate}&type=${chartType}`, loginType:"login"});

    useEffect(() => {
        const labels = insightApi?.data?.chart_data.map(item => item.date)
        let chartDataSet = {
            labels,
            datasets: [
                {
                    label: tab?.name,
                    data: insightApi?.data?.chart_data.map(item => item.value),
                    borderColor: (context) => {
                        const chart = context.chart;
                        const { ctx, chartArea } = chart;

                        if (!chartArea) {
                            return null;
                        }

                        const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
                        gradient.addColorStop(0, '#30795E');
                        gradient.addColorStop(1, '#1E98E8');

                        return gradient;
                    },
                    pointRadius: 0,
                    borderWidth: 5,
                }
            ]
        }

        setChartData(chartDataSet);

        setRadarData({
            labels: ["Vitality", "Irrigation", "Damage", "Soil", "Safety", "Pruning"],
            datasets: [
                {
                    label: "Scores",
                    data: [insightApi?.data?.review?.vitality_score, insightApi?.data?.review?.irrigation_score, insightApi?.data?.review?.damage_score, insightApi?.data?.review?.soil_score, insightApi?.data?.review?.safety_score, insightApi?.data?.review?.pruning_score],
                    borderColor: "#6FD195",
                    borderWidth: 2,
                    pointRadius: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.15)',
                },
            ],
        })
    }, [insightApi]);

    return (
        <ContentsSection
            header={true}
            footer={true}
            addClass={"gBg"}
        >
            <LeftMenu headTitle={`<div class="title">ID <span class="green">${id}</span></div>`}/>
            <div className="contents">
                <Header/>
                <div className="contentsNoBox">
                    <div className="contentsGrid">
                        <div className={`card mCard full`}>
                        </div>
                        <div className="cardBox mr-30">
                            <div className={`card pcCard ${tab?.code === '11' ? 'active' : ''}`} onClick={() => setTab({'name': 'Tree vitality', 'code': '11'})}>
                                <div className="cardTitle">
                                    <div>Tree vitality</div>
                                    <div>{insightApi?.data?.tree_vitality?.value_diff >= 0 ? ('+ ' + insightApi?.data?.tree_vitality?.value_diff) : insightApi?.data?.tree_vitality?.value_diff} {insightApi?.data?.tree_vitality?.unit} <img src="/assets/images/icon/ArrowRise.svg"/></div>
                                </div>
                                <div className="cardContents">
                                    <div className="cardContentsBox">
                                        <div className="title">Min</div>
                                        <div className="contents">{insightApi?.data?.tree_vitality?.min_value} {insightApi?.data?.tree_vitality?.unit}</div>
                                    </div>
                                    <div className="cardContentsBox">
                                        <div className="title">Max</div>
                                        <div className="contents">{insightApi?.data?.tree_vitality?.max_value} {insightApi?.data?.tree_vitality?.unit}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={`card mCard ${tab?.code === '11' ? 'active' : ''}`} onClick={() => setTab({'name': 'Tree vitality', 'code': '11'})}>
                                <div className="cardTitle">
                                    <div>Tree vitality</div>
                                    <div>{insightApi?.data?.tree_vitality?.min_value} {insightApi?.data?.tree_vitality?.max_value}</div>
                                </div>
                                <div className="cardContents">{insightApi?.data?.tree_vitality?.value_diff >= 0 ? ('+ ' + insightApi?.data?.tree_vitality?.value_diff) : insightApi?.data?.tree_vitality?.value_diff} {insightApi?.data?.tree_vitality?.unit} <img src="/assets/images/icon/ArrowRise.svg"/></div>
                            </div>
                            <div className={`card pcCard ${tab?.code === '1' ? 'active' : ''}`} onClick={() => setTab({'name': 'Bark temperature', 'code': '1'})}>
                                <div className="cardTitle">
                                    <div>Bark temperature</div>
                                    <div>{insightApi?.data?.tree_temperature?.value_diff >= 0 ? ('+ ' + insightApi?.data?.tree_temperature?.value_diff) : insightApi?.data?.tree_temperature?.value_diff} {insightApi?.data?.tree_temperature?.unit} <img src="/assets/images/icon/ArrowRise.svg"/></div>
                                </div>
                                <div className="cardContents">
                                    <div className="cardContentsBox">
                                        <div className="title">Min</div>
                                        <div className="contents">{insightApi?.data?.tree_temperature?.min_value} {insightApi?.data?.tree_temperature?.unit}</div>
                                    </div>
                                    <div className="cardContentsBox">
                                        <div className="title">Max</div>
                                        <div className="contents">{insightApi?.data?.tree_temperature?.max_value} {insightApi?.data?.tree_temperature?.unit}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={`card mCard ${tab?.code === '1' ? 'active' : ''}`} onClick={() => setTab({'name': 'Bark temperature', 'code': '1'})}>
                                <div className="cardTitle">
                                    <div>Bark temperature</div>
                                    <div>{insightApi?.data?.tree_vitality?.min_value} {insightApi?.data?.tree_vitality?.max_value}</div>
                                </div>
                                <div className="cardContents">{insightApi?.data?.tree_vitality?.value_diff >= 0 ? ('+ ' + insightApi?.data?.tree_vitality?.value_diff) : insightApi?.data?.tree_vitality?.value_diff} {insightApi?.data?.tree_vitality?.unit} <img src="/assets/images/icon/ArrowRise.svg"/></div>
                            </div>
                            <div className={`card pcCard ${tab?.code === 'Suction' ? 'active' : ''}`} onClick={() => setTab({'name': 'Soil moisture Content', 'code': 'Suction'})}>
                                <div className="cardTitle">
                                    <div>Soil moisture Content</div>
                                    <div>{insightApi?.data?.soil_moisture_content?.value_diff >= 0 ? ('+ ' + insightApi?.data?.soil_moisture_content?.value_diff) : insightApi?.data?.soil_moisture_content?.value_diff} {insightApi?.data?.soil_moisture_content?.unit} <img src="/assets/images/icon/ArrowRise.svg"/></div>
                                </div>
                                <div className="cardContents">
                                    <div className="cardContentsBox">
                                        <div className="title">Min</div>
                                        <div className="contents">{insightApi?.data?.soil_moisture_content?.min_value} {insightApi?.data?.soil_moisture_content?.unit}</div>
                                    </div>
                                    <div className="cardContentsBox">
                                        <div className="title">Max</div>
                                        <div className="contents">{insightApi?.data?.soil_moisture_content?.max_value} {insightApi?.data?.soil_moisture_content?.unit}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={`card mCard ${tab?.code === 'Suction' ? 'active' : ''}`} onClick={() => setTab({'name': 'Soil moisture Content', 'code': 'Suction'})}>
                                <div className="cardTitle">
                                    <div>Soil moisture Content</div>
                                    <div>{insightApi?.data?.tree_vitality?.min_value} {insightApi?.data?.tree_vitality?.max_value}</div>
                                </div>
                                <div className="cardContents">{insightApi?.data?.tree_vitality?.value_diff >= 0 ? ('+ ' + insightApi?.data?.tree_vitality?.value_diff) : insightApi?.data?.tree_vitality?.value_diff} {insightApi?.data?.tree_vitality?.unit} <img src="/assets/images/icon/ArrowRise.svg"/></div>
                            </div>
                            <div className={`card pcCard ${tab?.code === 'Water Content' ? 'active' : ''}`} onClick={() => setTab({'name': 'Soil water potential', 'code': 'Water Content'})}>
                                <div className="cardTitle">
                                    <div>Soil water potential</div>
                                    <div>{insightApi?.data?.soil_water_potential?.value_diff >= 0 ? ('+ ' + insightApi?.data?.soil_water_potential?.value_diff) : insightApi?.data?.soil_water_potential?.value_diff} {insightApi?.data?.soil_water_potential?.unit} <img src="/assets/images/icon/ArrowRise.svg"/></div>
                                </div>
                                <div className="cardContents">
                                    <div className="cardContentsBox">
                                        <div className="title">Min</div>
                                        <div className="contents">{insightApi?.data?.soil_water_potential?.min_value} {insightApi?.data?.soil_water_potential?.unit}</div>
                                    </div>
                                    <div className="cardContentsBox">
                                        <div className="title">Max</div>
                                        <div className="contents">{insightApi?.data?.soil_water_potential?.max_value} {insightApi?.data?.soil_water_potential?.unit}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={`card mCard ${tab?.code === 'Water Content' ? 'active' : ''}`} onClick={() => setTab({'name': 'Soil water potential', 'code': 'Water Content'})}>
                                <div className="cardTitle">
                                    <div>Soil water potential</div>
                                    <div>{insightApi?.data?.tree_vitality?.min_value} {insightApi?.data?.tree_vitality?.max_value}</div>
                                </div>
                                <div className="cardContents">{insightApi?.data?.tree_vitality?.value_diff >= 0 ? ('+ ' + insightApi?.data?.tree_vitality?.value_diff) : insightApi?.data?.tree_vitality?.value_diff} {insightApi?.data?.tree_vitality?.unit} <img src="/assets/images/icon/ArrowRise.svg"/></div>
                            </div>
                            <div className={`card weatherCard pcCard active`}>
                                <div className="weatherCardImg">
                                    <img src='/assets/images/icon/weather.png'/>
                                </div>
                                <div className="weatherCardContents">
                                    <div>32º</div>
                                    <div>sunny cloudy</div>
                                </div>
                            </div>
                        </div>
                        <div className="subBox">
                            <MenuTitle
                                title={tab?.name}
                                useSelectType={true}
                                selectType={chartType}
                                setSelectType={setChartType}
                                useDateRange={true}
                                titleAddClass={'mShow'}
                                buttonBoxAddClass={'nonMbtn'}
                                beginDate={startDate}
                                setBeginDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}/>
                            <div className="lineChartArea">
                                <div className="lineChartBox">
                                    <Line options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        plugins: {
                                            legend: {display: false},
                                            title: {display: false},
                                            annotation: {
                                                annotations: {}
                                            }
                                        },
                                        scales: {
                                            x: {
                                                grid: {
                                                    color: '#FFFFFF33', // X축의 grid line 색상
                                                },
                                                ticks: {
                                                    color: '#F1F1F1', // Y축 레이블 색상
                                                    padding: 15
                                                },
                                            },
                                            y: {
                                                min: 0,
                                                grid: {
                                                    color: '#FFFFFF33', // Y축의 grid line 색상
                                                },
                                                ticks: {
                                                    color: '#F1F1F1', // Y축 레이블 색상
                                                    padding: 15
                                                },
                                            },
                                        },
                                    }} data={chartData}/>
                                </div>
                            </div>
                        </div>
                        <div className="flexContents">
                            <div className="subBox" style={{ width: "60%" }}>
                                <div className="menuTitleBox"><div className="title mShow">Management History</div></div>
                                <TableSection
                                    headType={true}
                                    headAddClass="pcContents"
                                    th={[
                                        {style:{minWidth:"12%",width:"12%"}, contents:`Location`},
                                        {style:{minWidth:"20%",width:"20%"}, contents:`Task name`},
                                        {style:{minWidth:"19%",width:"19%"}, contents:`Start Date`},
                                        {style:{minWidth:"19%",width:"19%"}, contents:`Finish Date`},
                                        {style:{minWidth:"10%",width:"10%"}, contents:`Name`},
                                        {style:{minWidth:"14%",width:"14%"}, contents:`Status`},
                                        {style:{minWidth:"6%",width:"6%"}, contents:``},
                                    ]}
                                >
                                    {insightApi?.data?.field_datas?.map((field, fieldKey) => (
                                        <Fragment key={fieldKey}>
                                            <TableTr addClass={`pcContents`} style={{ cursor: "pointer" }} func={() => navigate(`/field/detail/${field?.id}`)} mouseEnterFunc={() => { setMouseOver(fieldKey) }} mouseLeaveFunc={() => { setMouseOver('') }}>
                                                <TableTd addClass="alignLeft" style={{ minWidth:"12%", width:"12%" }}>{field?.region}</TableTd>
                                                <TableTd addClass="alignLeft" style={{ minWidth:"20%", width:"20%" }}>{field?.title}</TableTd>
                                                <TableTd addClass="alignLeft" style={{ minWidth:"19%", width:"19%" }}><div className="imageText"><img src="/assets/images/icon/calender.svg"/>{field?.start_date ? field?.start_date : '-'}</div></TableTd>
                                                <TableTd addClass="alignLeft" style={{ minWidth:"19%", width:"19%" }}><div className="imageText"><img src="/assets/images/icon/calender.svg"/>{field?.end_date ? field?.end_date : '-'}</div></TableTd>
                                                <TableTd addClass="alignLeft" style={{ minWidth:"10%", width:"10%" }}>{field?.name}</TableTd>
                                                <TableTd addClass="alignLeft" style={{ minWidth:"14%", width:"14%" }}>
                                                    {field?.status === 0 && (<div className="status purple"><img src="/assets/images/basic/purpleDot.svg"/>In Progress</div>)}
                                                    {field?.status === 1 && (<div className="status green"><img src="/assets/images/basic/GreenDot.svg"/>Complete</div>)}
                                                    {field?.status === 10 && (<div className="status blue"><img src="/assets/images/basic/blueDot.svg"/>Pending</div>)}
                                                </TableTd>
                                                <TableTd addClass="alignLeft" style={{ minWidth:"6%", width:"6%" }}>{mouseOver === fieldKey && (<img src="/assets/images/icon/arrow.svg"/>)}</TableTd>
                                            </TableTr>
                                            <TableTr addClass={`mContents`} func={() => {}} mouseEnterFunc={() => {}} mouseLeaveFunc={() => {}}>
                                                <TableTd addClass="">
                                                    <div className="tableLabel">Location</div>
                                                    <div className="tableValue">{field?.region}</div>
                                                </TableTd>
                                                <TableTd addClass="">
                                                    <div className="tableLabel">Task name</div>
                                                    <div className="tableValue">{field?.title}</div>
                                                </TableTd>
                                                <TableTd addClass="">
                                                    <div className="tableLabel">Start Date</div>
                                                    <div className="tableValue">{field?.start_date ? field?.start_date : '-'}</div>
                                                </TableTd>
                                                <TableTd addClass="">
                                                    <div className="tableLabel">Finish Date</div>
                                                    <div className="tableValue">{field?.end_date ? field?.end_date : '-'}</div>
                                                </TableTd>
                                                <TableTd addClass="">
                                                    <div className="tableLabel">Name</div>
                                                    <div className="tableValue">{field?.name}</div>
                                                </TableTd>
                                                <TableTd addClass="">
                                                    <div className="tableLabel">Status</div>
                                                    <div className="tableValue">
                                                        {field?.status === 0 && (<div className="status purple"><img src="/assets/images/basic/purpleDot.svg"/>In Progress</div>)}
                                                        {field?.status === 1 && (<div className="status green"><img src="/assets/images/basic/GreenDot.svg"/>Complete</div>)}
                                                        {field?.status === 10 && (<div className="status blue"><img src="/assets/images/basic/blueDot.svg"/>Pending</div>)}
                                                    </div>
                                                </TableTd>
                                            </TableTr>
                                        </Fragment>
                                    ))}
                                </TableSection>
                            </div>
                            <div className="subBox" style={{ width: "40%" }}>
                                <MenuTitle
                                    title="Overall Review"
                                    useDate={true}
                                    titleAddClass={'mShow'}
                                    buttonBoxAddClass={'nonMbtn'}
                                    date={date}
                                    setDate={setDate}/>
                                <div className="radarChartArea">
                                    <div className="radarChartBox">
                                        <Radar data={radarData} options={{
                                            scales: {
                                                r: {
                                                    angleLines: {
                                                        display: true,
                                                    },
                                                    suggestedMin: 0,
                                                    suggestedMax: 100,
                                                    pointLabels: {
                                                        font: {
                                                            size: 12,
                                                        },
                                                        color: '#FFF',
                                                    },
                                                    ticks: {
                                                        stepSize: 20,
                                                        backdropColor: 'rgba(0, 0, 0, 0)',
                                                    },
                                                },
                                            },
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                },
                                            },
                                        }} />
                                    </div>
                                </div>
                                <div className="commentBox">
                                    <div className="title">Comments</div>
                                    <div className="contents">{insightApi?.data?.review?.insight_comment}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ContentsSection>
    );
};

export default Main;